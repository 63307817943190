.component-wrapper {
  position: relative;

  &-item {
    &-wrapper {
      margin: 8px 4px;
      padding: 4px;
      border-radius: 8px;
      &-disabled {
        opacity: 0.3;
      }

      &:hover {
        .component-wrapper-delete {
          opacity: 1;
        }
      }
    }

    margin: 0px;
    padding: 0 8px;
  }

  &-delete {
    position: absolute;
    top: 0px;
    right: 16px;
    z-index: 1;
    opacity: 0;

    &:hover {
      color: red;
    }
  }

  &-visible {
    border-radius: 8px;
    border: 1px dashed black;
  }

  &-not-visible {
    border-radius: 8px;
    height: 0;
    overflow: hidden;
    border: 1px dashed red;
  }
}
