.dynamic-form-item-render {
  &-wrapper {
    margin-block: 42px !important;
    background-color: white;
    width: 420px;
    // border: 1px solid black;
    border-radius: 8px;
    margin: 0 8px;

    overflow: auto;
  }

  &-header {
    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      padding: 8px 12px;

      :global {
        .ant-form-item {
          margin-block-end: 0;
        }
      }
    }

    &-title {
      display: flex;
      flex-flow: row nowrap;
      margin-block-end: 8px;
    }

    &-description {
    }
  }
}
