.plugin-card {
  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 8px;
    justify-content: space-between;
    width: 426px;
    height: 208px;

    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
  }

  &-header {
    &-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    &-info {
      display: flex;
      flex-flow: column nowrap;
      row-gap: 8px;

      &-name {
        color: #2a2c2f;
        font-size: 16px;
        font-weight: 700;
      }

      &-time {
        color: rgba(42, 44, 47, 0.4);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  &-description {
    flex: 1;
    margin-block-start: 8px;
    margin-block-end: 0 !important;

    color: rgba(42, 44, 47, 0.7);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;

    &-actions {
      padding: 8px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
