.dynamic-form {
  &-wrapper {
    display: flex;
    flex-flow: row nowrap;

    width: 100%;
  }

  &-item-render-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #f7f9fa;
  }
}
