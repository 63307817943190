.layout {
  &-wrapper {
    height: 100vh;
    overflow: auto;

    :global {
      /* Global scrollbar styles */

      div::-webkit-scrollbar {
        width: 8px;
        height: 1px;
      }

      div:hover::-webkit-scrollbar-thumb {
        background: #eee;
        box-shadow: inset 0 0 5px rgba(182, 176, 176, 0.2);
      }

      div::-webkit-scrollbar-thumb {
        border-radius: 4px;
        box-shadow: none;
        background: transparent;
      }

      div::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 4px;
        background: transparent;
      }
    }
  }

  &-sider {
    &-wrapper {
      background-color: #f7f9fa;

      &:hover {
        .layout-sider-trigger-box {
          opacity: 1;
        }
      }
    }

    &-organization-list {
      &-wrapper {
        margin-block-end: 16px;
        background-color: #f7f9fa;
        color: #2a2c2fb2;
        height: 40px;
        border-right: unset !important;

        :global {
          .ant-menu-submenu-title {
            margin: 0 24px 0 32px;
            padding: 4px 9px !important;
            width: calc(100% - 60px);
          }

          .ant-menu-item-selected {
            background-color: #ddeeff;
            color: #3278d9;
          }
        }

        &:global:not(.ant-menu-inline-collapsed) {
          .ant-menu-submenu-title {
            display: flex;
            align-items: center;
            width: calc(100% - 56px);
          }

          .ant-menu-item-selected {
            overflow: visible;

            &::after {
              content: '';
              position: absolute;
              width: 4px;
              background-image: url('/assets/images/menu-selected.svg');
              right: -24px !important;
            }
          }
        }
      }

      &-icon {
        font-size: 32px !important;

        &-unfold {
          position: absolute;
          font-size: 20px !important;
          top: 50%;
          inset-inline-end: 8px;
          color: currentcolor;
          transform: translateY(-50%);
        }
      }

      &-text {
        flex: 1;
        color: #2a2c2f;
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 88px;
      width: 100%;

      &-icon {
        height: 35px;
        width: 35px;

        &-with-name {
          height: 43px;
          width: 245px;
        }
      }
    }

    &-content {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
    }

    &-trigger {
      &-box {
        opacity: 0;
        width: 12px;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-bg {
        position: absolute;
        width: 12px;
        height: 36px;
      }

      &-arrow {
        position: absolute;
        width: 20px;
        height: 20px;

        &-reverse {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-content {
    &-wrapper {
      display: flex;
      flex-flow: column nowrap;
      overflow: hidden;
      background-color: #fff;
    }
  }
}
