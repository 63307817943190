.feedback {
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 20px 24px;
    border-radius: 16px;
    border: 1px solid #d1d1d1;
    background-color: white;
  }

  &-title {
    flex: 1;
    color: #595959;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  &-icon {
    &-close {
      cursor: pointer;
      border-radius: 50%;

      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url('/assets/images/cross.svg');
      background-repeat: no-repeat;
    }
  }

  &-results {
    &-wrapper {
      display: flex;
      flex-flow: row wrap;
      gap: 8px 4px;

      margin-block-start: 16px;
    }

    &-btn {
      border-radius: 100px !important;
      &:hover {
        border: 1px solid black;
      }

      &-selected {
        background-image: linear-gradient(90deg, #d3dd46 0%, #a7cb19 100%);

        &:hover {
          background-image: linear-gradient(
            90deg,
            #d3dd46 0%,
            #a7cb19 100%
          ) !important;
          border: 1px solid black;
        }
      }
    }

    &-description {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px !important;
      padding: 16px 24px;

      &-wrapper {
        margin-block-start: 24px;
      }
    }
  }

  &-submit {
    width: 86px;
    height: 48px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    &-wrapper {
      align-self: flex-end;
      margin-bottom: 0;
      overflow: hidden;
    }
  }
}
