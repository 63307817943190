.tableIcon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.tag-enabled {
  margin: 1px;
  background-color: #ebfaf0;
  color: #2abb71;
  border: none;
  padding: 2px 8px;
  display: inline-flex;
  gap: 4px;
}

.tag-disabled {
  margin: 1px;
  background-color: #fff4f0;
  color: #d94032;
  border: none;
  padding: 2px 8px;
  display: inline-flex;
  gap: 4px;
}

.tag-active {
  background-color: #f0f9ff;
  color: #3278d9;
  border: none;
  padding: 2px 8px;
}

.tag-inactive {
  margin: 1px;
  background-color: #f7f9fa;
  color: #2a2c2f;
  border: none;
  padding: 2px 8px;
}
