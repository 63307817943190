.document-upload {
  &-wrapper {
    padding: 32px;
    overflow: auto;
  }

  &-settings {
    :global {
      .ant-form {
        .ant-radio-group {
          display: flex;
          flex-direction: column;
          gap: 14px;
        }

        .text-processing-rules {
          margin-bottom: 14px;
        }

        .text-processing-rules .ant-form-item {
          margin-bottom: 0px;
        }

        .ant-input-number {
          width: 100%;
        }

        button[type='submit'] {
          margin-top: 14px;
          width: 100%;
        }
      }
    }
  }
}
