.question-template-link {
  &:hover {
    color: var(--ant-color-link-hover);
    text-decoration: underline;
  }
}

:global(.ant-upload-wrapper .ant-upload-drag) {
  p.upload-hint {
    font-size: 14px;
  }
}
