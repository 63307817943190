.list {
  &-drop-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    background-color: #e6f7ff;
    border-radius: 8px;
    border: 1px dashed #3278d9;
    color: #3278d9;
  }
}
