.output {
  &-wrapper {
  }

  &-tree {
    &-wrapper {
      max-height: 360px;
      overflow: auto;
    }

    &-item {
      &-wrapper {
        display: flex;
        column-gap: 4px;
      }

      &-name {
        // flex: 1;
        // width: 240px !important;
      }

      &-required {
        width: 240px !important;
      }

      &-type {
        width: 240px !important;
      }

      &-action-btn {
        width: 32px;
        height: 32px;
      }
    }

    &-add-btn {
      margin-block-start: 12px;
      // color: #3278d9;
    }
  }
}
