.plugin {
  &-wrapper {
    height: 100%;
    margin: 24px 16px;
    display: flex;
    flex-flow: column nowrap;
  }

  &-header {
    &-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
    }

    &-actions {
      &-icon {
        font-size: 20px;
        width: 36px !important;
        height: 36px;
      }
    }
  }

  &-content-wrapper {
    flex: 1;
    margin-block-start: 24px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  &-card {
    &-wrapper {
      display: flex;
      flex-flow: row wrap;
      gap: 24px;
    }
  }
}
