.settings-form input[id='settings_organizationType'] {
  text-transform: capitalize;
}

.settings-form .actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 0;
}

.settings-form .actions .ant-btn {
  font-weight: 700;
  font-size: 14px;
}

.settings-form .avatar-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-form .avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}
