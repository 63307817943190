.basic-info {
  &-wrapper {
    height: calc(100vh - 88px);
    padding: 20px;
    overflow-y: auto;
  }

  &-item {
    &-wrapper {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &-anchor-wrapper > div {
    position: sticky;
    top: 0;
  }
}
