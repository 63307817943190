.chunk-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 210px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #eaecef;
  padding: 16px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px #eee;
  }

  &-content {
    font-size: 12px;
    color: #393349;
    margin: 12px 0;
    width: 260px;
    height: 38px;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  &-button {
    text-align: center;
    .ant-btn-primary {
      width: 120px;
    }
  }
}
