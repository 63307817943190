.agent-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 384px;
  height: 199px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f7f9fa;
  border: 1px solid #eaecef;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .agent-actions {
      .action-buttons {
        opacity: 1;
        transform: scale(1);
        pointer-events: auto;
      }
    }
  }
}

/* Header section */
.agent-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  height: 54px;
  margin-bottom: 16px;
  gap: 16px;
}

.agent-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 186px;
  height: 50px;
  margin: 0 auto;
}

.agent-name {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 24px;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #2a2c2f;

  &:global(.ant-typography) {
    margin: 0;
  }
}

.agent-update-time {
  width: 100%;
  height: 18px;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(42, 44, 47, 0.4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agent-icon {
  width: 54px;
  height: 54px;
  background-color: #eaecef;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;

  :global(.ant-avatar) {
    background-color: #eaecef;
  }
}

/* Description section */
.agent-description {
  width: 100%;
  height: 61px;
  font-style: normal;
  margin-bottom: 16px;

  :global(.ant-typography) {
    font-weight: 500;
    line-height: 20px;
    color: #2a2c2fb2;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}

/* Footer section */
.agent-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 36px;
}

.agent-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 4px;
  gap: 4px;
  height: 20px;
  background: #f7f9fa;
  border-radius: 4px;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #2a2c2f;
}

.agent-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
  height: 36px;
  border-radius: 8px;

  .action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transform: scale(0.9);
    pointer-events: none;
    transition:
      opacity 0.2s ease,
      transform 0.2s ease;
  }

  :global(.ant-btn) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 36px;
    height: 36px;
    border-radius: 8px;

    // Make the EllipsisOutlined icon dots thicker
    :global(.anticon-ellipsis) {
      svg {
        width: 1.2em;
        height: 1.2em;

        path {
          stroke-width: 20;
          stroke: currentColor;
        }
      }
    }
  }
}
