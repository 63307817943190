.metrics-table {
  margin: 16px;
}

.table-icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.evaluation-type-tag {
  background-color: #f7f9fa;
  color: #2a2c2f;
  border: none;
  padding: 2px 8px;
}
