.analytics {
  &-wrapper {
    overflow: auto;
    padding: 16px;
  }

  &-header {
    padding-bottom: 8px;

    &-title {
      font-size: 36px;
      font-weight: 700;
    }

    &-timezone-dropdown-wrapper {
      :global {
        .ant-dropdown-menu {
          max-height: 50vh;
          overflow-y: auto;
        }
      }
    }
  }
}
