.organization {
  &-wrapper {
    height: 100%;
    margin: 24px 16px;
  }

  &-title {
    height: 38px;
    color: #2a2c2f;
    font-size: 30px;
    font-weight: 700;
  }

  &-tab {
    &-wrapper {
      height: calc(100vh - 2 * 24px - 38px - 24px);
      margin-block-start: 24px;

      :global {
        .ant-tabs-content,
        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }

    &-count {
      border-radius: 16px;
      margin-inline-start: 12px;
      color: #2a2c2f;
      background-color: #f7f9fa;

      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
