:global {
  .ant-menu-item.ant-menu-item-selected[editable='true'] {
    background-color: #fff !important;
    border: 2px solid #3278d9;
    transition: none;
  }
}

.session-item {
  &-title {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  :global {
    .ant-input {
      border: none;
      padding: 0;
      box-shadow: none;
    }
  }
}

.menu-wrapper {
  position: absolute;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}

.menu-item {
  display: flex;
  min-width: 78px;
  background: none;
  border: none;
  padding: 0;
  gap: 12px;

  :global {
    .anticon-delete {
      color: #d94032;
    }
  }
}
