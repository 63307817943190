.plugin-detail {
  &-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  &-header {
    &-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px;
      border-bottom: 1px solid #eaecef;
    }

    &-left {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;

      &-back-icon {
        width: 32px;
        height: 32px;
        padding: 6px;
        font-size: 20px;
        cursor: pointer;
      }

      &-info {
        &-name {
          color: #2a2c2f;
          font-size: 18px;
          font-weight: 700;
        }

        &-sub-wrapper {
          display: flex;
          column-gap: 4px;
          color: rgba(42, 44, 47, 0.4);
          font-size: 12px;
          font-weight: 500;
        }

        &-status {
          text-transform: capitalize;
        }
      }
    }

    &-right {
      &-btn {
        height: 36px;
      }
      &-icon {
        font-size: 20px;
      }
    }
  }

  &-tab {
    &-wrapper {
      height: 40px;

      :global {
        .ant-tabs-nav {
          margin-block-end: 0;

          &::before {
            display: none;
          }
        }
        .ant-tabs-tab {
          display: flex;
          align-items: center;
          height: fit-content;
          padding: 8px;
          border-radius: 8px;
        }

        .ant-tabs-tab-active {
          background-color: #f0f9ff;

          .ant-tabs-tab-btn {
            color: #3278d9 !important;
            // font-weight: 700;
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }

        .ant-tabs-tab-btn {
          display: flex;
          align-items: center;
          line-height: normal;

          font-size: 16px;
          font-weight: 500;
        }

        .ant-tabs-tab-icon {
          height: 24px;
        }
      }
    }

    &-item {
      &-icon {
        font-size: 24px;
        line-height: normal !important;
      }
    }
  }

  &-dynamic-form-wrapper {
    height: calc(100% - 90px);
  }

  &-steps-wrapper {
    max-width: 800px;
    padding-inline: 16px;
  }

  &-content-tabs-wrapper {
    :global {
      .ant-tabs-nav {
        display: none;
      }
    }
  }

  &-hide {
    display: none;
  }
}
