.feedback-wrapper {
  width: 100%;
  height: 250px;
  position: relative;
  top: -30px;

  &-likes-dislikes-diagram {
    width: 100%;
    height: 100%;
  }

  &-likes-dislikes-label {
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: 0;
    left: -20%;

    &-count {
      padding-top: 82px;
      font-weight: 600;
      font-size: 28px;
      color: #2a2c2f;
      line-height: normal;
    }

    &-text {
      font-size: 14px;
      color: #2a2c2f66;
    }
  }

  &-top-intents {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &-title {
      font-weight: 400;
      margin-bottom: 12px;
    }

    &-list {
      padding: 0 4px;
      height: 60vh;
      overflow-y: auto;

      &-item {
        :global {
          color: #2a2c2f;

          .count-tier-1 {
            color: #3278d9;
            font-weight: 400;
          }

          .count-tier-2 {
            color: #2abb71;
            font-weight: 400;
          }
        }
      }

      :global {
        .ant-typography-expand,
        .ant-typography-collapse {
          color: #03a9f4;
        }
      }
    }
  }
}
