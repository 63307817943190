.twoFactorAuth {
  :global(.ant-modal-content) {
    padding-bottom: 8px;
  }
  :global(.ant-steps-item-title) {
    font-size: 16px;
  }
  :global(.ant-steps-item-finish) {
    padding-bottom: 16px;
  }

  .twoFactorAuthForm {
    padding-block-start: 20px;
  }
  .step2 {
    :global(.ant-steps-item-content .ant-steps-item-description) {
      p.step2Description {
        margin: 0;
        color: #2a2c2f66;
      }
    }
  }
}
