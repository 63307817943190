.workflow-status-wrapper {
  width: fit-content;
  padding: 4px 8px;
  color: #9de0b7;
  border: 1px solid;
  border-radius: 4px;
  gap: 4px;

  &-success {
    color: #199459 !important;
  }

  &-failed {
    color: #d94032 !important;
  }

  &-initiating {
    color: #9de0b7 !important;
  }

  &-processing {
    color: #3278d9 !important;
  }
}

.workflow-status-overlay {
  :global {
    .ant-dropdown-menu-root {
      padding: 0;
      border: 1px solid #eaecef;
      border-radius: 4px;
      max-height: 684px;

      .ant-dropdown-menu-item {
        padding: 0;
      }
    }
  }
}

.workflow-status-item {
  :global {
    .node-no {
      padding: 8px;
      min-width: 24px;
      border-inline-end: 1px solid #eaecef;
      justify-content: center;
    }

    .node-name {
      display: inline;
      padding: 8px;
      width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }

    .node-status {
      padding: 8px;
    }

    .node-duration {
      padding: 8px;
      color: #2a2c2f66;
    }

    .node-logs {
      display: none;
      font-size: 12px;
      padding: 8px;
      max-width: 720px;
      overflow-x: auto;

      pre {
        color: #2a2c2f;
        border: none;
        background: none;
        margin: 0;
        text-wrap: wrap;
      }
    }

    a.node-body {
      color: inherit;
    }

    a.node-body.active ~ .node-logs {
      display: block;
    }
  }

  &-success {
    :global {
      .node-no {
        color: #9de0b7;
        background-color: #ebfaf0;
      }
    }
  }

  &-failed {
    :global {
      .node-no {
        color: #d94032;
        background-color: #fff4f0;
      }

      .node-name {
        color: #d94032;
      }

      .node-duration {
        color: #e66a5a;
      }
    }
  }

  &-initiating {
    :global {
      .node-no {
        background-color: #eff1f4;
      }
    }
  }

  &-processing {
    :global {
      .node-no {
        color: #3278d9;
        background-color: #f0f9ff;
      }
    }
  }
}
