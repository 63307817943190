.main-layout-wrapper {
  height: 100%;

  &-header {
    padding: 24px 16px;
    border-bottom: 1px solid #eaecef;

    &-avatar {
      background-color: #eaecef;
      border: none;

      & > * {
        width: 24px;
        height: 24px;
        color: #2a2c2f;
        justify-content: center;
      }
    }

    &-title {
      color: #2a2c2f;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
    }

    &-caption {
      color: #2a2c2f66;
      font-size: 12px;
      line-height: normal;
    }

    &-no-back {
      & > *:first-child {
        // Hidden the back button and avatar
        > :not(:last-child) {
          display: none;
        }

        // Show the title with larger font size
        > :last-child {
          > :first-child {
            font-size: 32px;
          }
        }
      }
    }

    &-no-header-bottom-line {
      border-bottom: none;
    }

    &-menu {
      align-content: center;

      :global {
        .ant-radio-wrapper {
          padding: 8px;
          margin: 0;
        }

        .ant-radio-wrapper .ant-radio {
          display: none;
        }

        .ant-radio-wrapper.ant-radio-wrapper-checked {
          color: #3278d9;
          background-color: #F0F9FF;
          border-radius: 8px;
        }
      }
    }
  }
}
