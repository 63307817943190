.main-container {
  overflow: hidden;
  flex: 1;

  :global {
    .ant-splitter-bar .ant-splitter-bar-dragger:before {
      width: 1px;
    }
  }
}

.left-section {
  // min-width: 292px;
  // border-right: 1px solid #eaecef;
  height: 100%;

  &-header {
    padding: 24px 16px;
    border-bottom: 1px solid #eaecef;
  }

  &-search-button {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    background-color: #ebebeb;
  }

  &-session {
    padding: 8px 16px;

    &-new-button {
      background-color: #3278d9;
      color: #fff;
      border-radius: 8px;
      border: none;
      min-width: 32px;
      min-height: 32px;
    }
  }

  &-filter {
    margin: 4px 16px;
    padding: 4px 8px;
    background-color: #fff;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;

    &-range {
      padding: 0 8px;
      width: 100%;
      justify-content: flex-start;
    }
  }

  &-history {
    flex: 1;
    overflow: auto;
    margin-top: 4px;

    &-list {
      width: 100% !important;
      background-color: initial !important;
      border-inline-end: 0 !important;

      :global {
        .ant-menu-item {
          color: #2a2c2fb2;

          .ant-menu-title-content > * {
            display: inline-flex;
            white-space: nowrap;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            width: 100%;

            > button {
              display: none;
              color: #8a8c8f;
              padding: 0;
              font-weight: bold;
            }

            > button:hover {
              color: #000;
            }
          }

          &.ant-menu-item-active {
            .ant-menu-title-content > * > button {
              display: block;
            }
          }

          &.ant-menu-item-selected {
            background-color: #eff1f4;
            color: #2a2c2f;
            font-weight: bold;

            .ant-menu-title-content > * > button {
              display: block;
            }
          }
        }
      }
    }
  }

  &-navigation {
    padding: 12px 32px;
    justify-content: space-between;

    :global {
      .ant-btn {
        color: #2a2c2f;
        font-weight: bold;
        background-color: #eff1f4;
        border: none;

        &:hover {
          background-color: #eff1f4aa !important;
        }
      }

      .ant-input {
        width: 48px;
        text-align: center;
      }
    }
  }
}

.chat-section {
  background-color: #fff;
  align-items: center;
  padding: 24px 0;
  flex: 1;
  height: 100%;

  &-message-container {
    padding: 0 16px;
    width: 100%;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
  }

  &-message-inner {
    display: flex;
    flex: 1;
    padding: 0 16px;
    max-width: 820px;
  }

  &-input-container {
    padding: 0 16px;
    width: 100%;
    justify-content: center;
  }

  &-input-inner {
    max-width: 820px;
    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 8px;
    flex: 1;

    :global {
      .ant-input-outlined:focus {
        box-shadow: none;
      }
    }

    &-functions {
      justify-content: flex-end;
    }
  }
}

.chat-start-page {
  color: #2a2c2f66;
  flex: 1;
  justify-content: space-around;
  position: relative;
  margin: 0 48px;
  min-height: 480px;

  &-background {
    position: absolute;
    top: -25%;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(#3278d999, #d9d9d900);
    mask-image: url('/assets/images/agent-chat-bg.png');
    mask-size: 135%;
    mask-position: center;
    opacity: 0.6;
  }

  &-header {
    align-items: center;
    text-align: center;

    &-avatar {
      font-size: 24px;
      font-weight: bold;
      color: #2a2c2f;
      align-items: center;
    }
  }

  &-questions {
    :global {
      .ant-btn {
        width: fit-content;
        color: #2a2c2fb2;
      }
    }
  }
}
