.dynamic-form-item-selector {
  &-wrapper {
    flex: 1;
    height: fit-content;
    position: sticky;
    top: 0px;
    margin: 0 8px;
  }

  &-header {
    padding: 10px 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  &-content {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
  }

  &-default {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-icon {
      border: unset;
      border-radius: 8px;
      -webkit-user-drag: none;
    }

    &-name {
      width: 80px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
  }
}

.default-icon-size {
  width: 100%;
  height: 100%;
}
