.custom-table {
  &-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
  }

  &-content {
    &-wrapper {
      flex: 1;

      :global {
        .ant-table .ant-table-container .ant-table-body {
          overflow: auto !important;
        }
      }
    }
  }

  &-pagination {
    &-wrapper {
      margin-block-start: 16px;
      align-items: end;
    }

    &-align-end {
      :global {
        .ant-pagination-total-text {
          flex: 1;
        }
      }
    }
  }
}
