.modal {
  min-width: 750px;

  &-search-input-wrapper {
    margin-top: 8px;
    padding-right: 4px;

    :global {
      .anticon {
        color: #2a2c2f66;
      }

      .anticon-close {
        width: 10px;
      }
    }
  }

  &-search-categories {
    padding-bottom: 8px;
    border-bottom: 1px solid #eaecef;

    &-button {
      color: #2a2c2fb2;
      text-transform: capitalize;
      min-width: 100px;
    }

    &-button-selected {
      color: #2a2c2f;
      background-color: #f6f6f6;
      font-weight: bold;
    }
  }

  &-search-options-wrapper {
    justify-content: space-between;

    &-date-range-dropdown {
      border: none;
      box-shadow: none;
    }

    :global {
      .ant-btn {
        color: #2a2c2f66;
      }

      .ant-picker.ant-picker-range {
        border: none;
      }
    }
  }

  &-search-results-wrapper {
    min-height: 520px;
    justify-content: center;
  }

  &-search-results-container {
    flex: 1;
    padding: 8px;
  }

  &-search-results-item {
    color: #2a2c2fb2;
    padding-left: 16px;

    &-session {
      font-size: 16px;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    &-avatar {
      :global {
        .ant-avatar {
          border: 1px solid #eff1f4;
        }
      }
    }

    &-name {
      font-size: 16px;
      font-weight: 500;
    }

    &-content {
      font-size: 14px !important;
      background-color: #eff1f4 !important;
      padding: 16px;
      border-radius: 8px;
    }

    &-feedback-wrapper {
      color: #2a2c2f;
      background-color: #eff1f4;
      border-radius: 8px;
      padding: 8px 14px;

      &-labels > * {
        color: #3278d9;
        padding: 2px 8px;
        background-color: #f0f9ff;
        border: 1px solid #a4c7e8;
        border-radius: 4px;
      }

      &-thumbs {
        color: #3278d9;
      }
    }

    &-options-wrapper {
      // display: flex;
      display: none;
      padding: 0 8px;
      justify-content: flex-end;
    }
  }
}
