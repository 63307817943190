.chunks {
  &-card-wrapper {
    padding: 0 16px 16px 16px;
    overflow: auto;
  }

  &-pagination {
    width: 100%;
    padding: 16px;
    margin-top: auto;
  }
}
