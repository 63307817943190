.knowledge {
  &-card-wrapper {
    width: 384px;
    height: 163px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #eaecef;

    &:hover {
      box-shadow: 1px 3px 4px 0px #eaecef;
      cursor: pointer;
    }
  }

  &-avatar {
    background-color: #eaecef;
    border: none;
  }

  &-name {
    color: #2a2c2f;
    font-size: 16px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-meta-info {
    color: #2a2c2f66;
    font-size: 12px;
  }

  &-menu-button {
    color: #595c5f;
    padding: 10;
  }

  &-description {
    color: #2a2c2fb2;
    font-size: 14px;
  }
}
