.file-name-edit-button {
  color: #3278d9;
  font-weight: 700;
}

.table-icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.file-table {
  margin: 16px;

  :global {
    .file-status {
      display: inline-flex;
      text-transform: capitalize;
      padding: 2px 10px;
      border-radius: 16px;

      .anticon {
        width: 12px;
        height: 12px;
      }

      &.status-processed {
        background-color: #ebfaf0;
        color: #2abb71;

        .anticon {
          color: #2abb71;
        }
      }

      &.status-failed {
        background-color: #fff4f0;
        color: #d94032;

        .anticon {
          color: #d94032;
        }
      }

      &.status-processing {
        background-color: #fffbe6;
        color: #f5a623;

        .anticon {
          color: #f5a623;
        }
      }

      &.status-pending {
        background-color: #f0f9ff;
        color: #3278d9;

        .anticon {
          color: #3278d9;
        }
      }
    }
  }
}
