.agent-list {
  display: flex;
  flex-wrap: wrap;
  padding-inline: 16px;
  padding-block-end: 16px;
  gap: 16px;
  overflow-y: auto;
  height: calc(100vh - 150px);
  align-content: flex-start; // prevent agent card gap stretching
}
