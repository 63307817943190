.knowledge {
  &-content-wrapper {
    height: 100%;
    padding: 16px 0;
  }

  &-card-wrapper {
    padding: 0 16px 16px 16px;
    overflow: auto;
  }

  &-pagination {
    width: 100%;
    padding: 16px;
    margin-top: auto;
  }
}
