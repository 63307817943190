.padding-bottom {
  margin-bottom: 36px;
}

.avatar-uploader {
  :global(.ant-upload-select) {
    background-color: #f7f9fa !important;
  }

  button {
    > span {
      padding-bottom: 12px;
    }
  }
}
