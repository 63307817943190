.item-list {
  &-item-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    row-gap: 4px;

    :global {
      .ant-space-compact {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  &-item-compact {
    &-wrapper {
      // width: 80%;
    }
  }

  &-item-no-compact {
    &-wrapper {
      display: flex;
      flex-flow: column wrap;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }
  }
}
