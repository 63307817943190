.main-layout-header {
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: #fff;
  position: relative;
  min-height: 64px;
}

.main-layout-header .sub-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-layout-header .sub-menu .ant-menu-item-selected .ant-menu-title-content {
  color: #38304d;
  border-bottom: solid 2px #555;
}

.main-layout-header .sub-menu .ant-menu-item-selected::after {
  border: 0;
}

.main-layout-header .sub-menu .ant-menu-item-active::after,
.ant-menu-item::before {
  border: 0 !important;
}

.main-layout-header .sub-menu .ant-menu-horizontal {
  border: 0;
  width: 100%;
  justify-content: center;
}

.main-layout-header .sub-menu .ant-menu-horizontal > .ant-menu-item::after,
.main-layout-header
  .sub-menu
  .ant-menu-horizontal
  > .ant-menu-item:hover::after,
.main-layout-header
  .sub-menu
  .ant-menu-horizontal
  > .ant-menu-item-active::after,
.main-layout-header .sub-menu .ant-menu-horizontal > .ant-menu-item-open::after,
.main-layout-header
  .sub-menu
  .ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover:has(.deselected-item) {
  background-color: transparent !important;
  cursor: unset;
}
