.userProfileWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.userProfileInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.userProfileLogout {
  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

// button styles for menu items
.menuItemButton {
  padding: 0;
  height: var(--ant-menu-item-height);

  &:global(.ant-btn.ant-btn-text) {
    &:hover {
      background-color: transparent;
    }
  }
  &.withIcon {
    gap: 8px;
  }
}

.userProfileSiderWrapper {
  display: flex;
  padding-inline-start: 32px;
  padding-inline-end: 8px;
  padding-block-end: 24px;
  background: transparent;
  border-inline-end: none !important;
  user-select: none;

  .userProfileName {
    font-weight: 700;
    font-size: 16px;
    max-width: 100px;
    margin-right: auto;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  :global(.ant-menu-title-content) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :global(.ant-menu-submenu.ant-menu-submenu-popup) {
    top: 70vh !important;
    left: 35px !important;
  }

  :global(.ant-menu-submenu) {
    flex-grow: 1;
  }

  :global(.ant-menu-submenu .ant-menu-submenu-title) {
    padding-inline: 0;
    margin-bottom: 0;
    &:hover {
      background-color: transparent;
    }
  }

  :global(.ant-menu-item) {
    &.userProfileMenuItem {
      pointer-events: none;
      height: auto;
      line-height: normal;
      padding-inline: 8px;
      padding-block: 8px;
    }
  }
}

.userProfileSiderLabel {
  align-items: center;
  justify-content: space-between;
}

// Collapsed Menu States
// -------------------------
:global(.ant-menu-inline-collapsed) {
  &.userProfileSiderWrapper {
    justify-content: center;
    padding-inline: 0;
  }

  .userProfileName {
    display: none;
  }

  .userProfileLogout {
    display: none;
  }

  .userProfileSiderLabel {
    justify-content: center;
  }
}
