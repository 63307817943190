.message-wrapper {
  padding: 12px 0;

  &-avatar {
    :global {
      .ant-avatar {
        border: 1px solid #eff1f4;
      }
    }
  }

  &-name {
    color: #2a2c2fb2;
    font-size: 16px;
    font-weight: 500;
  }

  &-content {
    background-color: #eff1f4 !important;
    padding: 16px;
    border-radius: 8px;
  }

  &-extra {
    justify-content: space-between;
    align-items: center;
    color: #2a2c2f66;

    &-button-wrapper {
      vertical-align: middle;

      :global {
        :not(:last-child):after {
          content: '';
          width: 1px;
          height: 70%;
          align-self: center;
          background-color: #e3e3e3;
        }
      }
    }
  }

  &-feedback-wrapper {
    position: fixed;
    top: 30%;
    left: 48%;
    margin-top: 16px;
    padding: 20px 24px;
    color: #2a2c2f;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    width: 500px;
    z-index: 1000;

    :global {
      .feedback-results-wrapper .ant-btn {
        color: #2a2c2f;
        background-color: #f7f9fa;
        border: 1px solid #d5d6d9;
        border-radius: 6px !important;
        background-image: none !important;
      }

      .feedback-results-wrapper .ant-btn:hover {
        color: #3278d9;
        border: 1px solid #1890ff;
      }

      .feedback-results-wrapper .ant-btn.feedback-results-btn-selected {
        color: #3278d9;
        background-color: #f0f9ff;
        border: 1px solid #1890ff;
      }

      .feedback-submit {
        background-color: #3278d9;
        border-radius: 8px;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        width: inherit;
        height: inherit;
        padding: 6px 12px;
      }
    }
  }
}
