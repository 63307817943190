.evaluation-wrapper {
  overflow: hidden;
  padding-inline: 16px;
}

.evaluation-tabs {
  :global(.ant-tabs-nav) {
    padding-block-end: 2px;
    margin-bottom: 0;

    &::before {
      border-bottom: 1px solid #f7f9fa;
    }
  }

  :global(.ant-tabs-tab-active) {
    font-weight: 700;
  }
}
