.content-wrapper {
  padding-top: 24px;
}

.input-row {
  align-items: baseline;

  & > span {
    min-width: 84px;
  }
}

.timezone-dropdown-wrapper {
  :global {
    .ant-dropdown-menu {
      max-height: 50vh;
      overflow-y: auto;
    }
  }
}
