.custom-pagination {
  &-wrapper {
    :global {
      .ant-pagination-item {
        border-radius: 8px;
      }
      .ant-pagination-item-active {
        background-color: black;
        border: unset;

        > a, a:hover {
          color: white;
        }
      }
    }
  }

  &-arrow {
    padding: 4px;
    width: 36px;
    height: 36px;
    font-size: 20px;
    background-color: #eff1f4;
    border-radius: 8px;
    color: white;

    &-left {
      transform: rotate(180deg);
    }
  }
}
