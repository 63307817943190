.section-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #e3e3e3;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
}

.section-card {
  &-icon {
    width: 24px;
    border-radius: 4px;
    background: #ebebeb;
    justify-content: center;
  }

  &-title {
    font-size: 18px;
    font-weight: normal;
  }

  &-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
