.modal {
  :global(.ant-modal-header) {
    margin-bottom: 20px;
  }
}

.add-button {
  font-weight: 700;
  font-size: 14px;
  color: var(--ant-color-primary);
  border: 1px dashed #a4c7e8;
}
