.sidebar-menu {
  &-wrapper {
    flex: 1;
    border-right: unset !important;

    background-color: #f7f9fa;
    color: #2a2c2fb2;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    :global {
      .ant-menu-item {
        margin: 0 24px 0 32px;
        width: calc(100% - 60px);
        height: 40px;
        padding: 8px 16px;
        line-height: 24px;

        &:not(:first-child) {
          margin-block-start: 16px;
        }
      }

      .ant-menu-item-selected {
        background-color: #ddeeff;
        color: #3278d9;
      }
    }

    &:global:not(.ant-menu-inline-collapsed) {
      .ant-menu-item {
        width: calc(100% - 56px);
      }

      .ant-menu-item-selected {
        overflow: visible;

        &::after {
          content: '';
          position: absolute;
          width: 4px;
          background-image: url('/assets/images/menu-selected.svg');
          right: -24px !important;
        }
      }
    }
  }

  &-selected-icon {
    width: 4px;
    height: 40px;
  }
}
