.section-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #e3e3e3;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
}

.data-card {
  &-icon {
    width: 24px;
    border-radius: 4px;
    background: #ebebeb;
    justify-content: center;
  }

  &-title {
    font-size: 14px;
    font-weight: normal;
  }

  &-data {
    position: relative;
    align-items: baseline;
  }

  &-value {
    font-size: 36px;
    font-weight: bold;
  }

  &-diff {
    font-size: 16px;
    font-weight: bold;
  }

  &-suffix {
    color: #777;
  }
}

